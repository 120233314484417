import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Reinsurance_Cover.jpg';
import metaImage from './assets/meta.jpg';

const ReinsurancePage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-reinsurance"
      helmet={{
        title: 'TileDB for Reinsurance',
        description:
          'TileDB is a single scalable platform for all financial, geographical and enterprise data applied to risk modeling and more.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Reinsurance"
      title="Embrace a single scalable platform for all financial, geographical and enterprise data for risk modeling"
      heroImage={BackgroundImage}
      icon={industries.reinsurance.icon}
      overview={
        <>
          <p>
            Data diversity and advanced analytics are disrupting reinsurance, delivering unprecedented efficiencies and speed in the
            customer experience.
          </p>
          <p>
            The TileDB universal database is uniquely suited to fast-evolving data demands in reinsurance, structuring all geospatial,
            weather, satellite, maritime and time-series data in a single solution. With extreme interoperability and scalable performance
            across a variety of machine learning tools, TileDB Cloud is the ideal choice for complex use cases in flooding, lightning,
            hurricane, and wildfire simulations.
          </p>
        </>
      }
      relatedItems={[
        dataTypes.ais,
        dataTypes.timeSeries,
        dataTypes.sar,
        dataTypes.lidar,
        dataTypes.weather,
        industries.earthObservation,
        industries.maritime,
      ]}
    />
  );
};

export default ReinsurancePage;
